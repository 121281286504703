import React, { ReactNode } from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import uuid from 'uuid';

import { CheckMark } from '../components/buttons/CheckMark';

export interface FilterOption<T> {
  display_name: string;
  key: T;
  badge?: ReactNode;
}

export interface FilterLabel {
  label_name: string;
}

export interface FilterDropdownProps<T> {
  filterName: ReactNode;
  filterOptions: (FilterOption<T> | FilterLabel)[];
  filter: T[];
  setFilter: React.Dispatch<React.SetStateAction<T[]>>;
  searcheable?: boolean;
  disabled?: boolean;
}

export const FilterDropdown = <T,>({
  filterName,
  filterOptions,
  filter,
  setFilter,
  searcheable,
  disabled,
}: FilterDropdownProps<T>) => {
  const [searchString, setSearchString] = React.useState<string>('');
  const setSelectedFilter = (filterOption: T) => {
    const isAlreadySelected = filter.includes(filterOption);
    if (isAlreadySelected) {
      setFilter(filter.filter((filter) => filter !== filterOption));
    } else {
      setFilter([...filter, filterOption]);
    }
  };

  const filterOptionsFiltered = filterOptions.filter((filterOption) => {
    if ('label_name' in filterOption) {
      return true;
    }
    return filterOption.display_name.toLowerCase().includes(searchString.toLowerCase());
  });

  return (
    <div className="d-flex align-items-center gap-2">
      <Dropdown className="w-100">
        <Dropdown.Toggle
          variant="outline-info"
          className="w-100 d-flex gap-2 overflow-hidden bg-birch"
          disabled={disabled}
        >
          {filterName}
          {filter.length > 0 && (
            <Badge
              pill
              bg="primary"
              className="nav-item-badge text-center d-flex bg-primary text-dark align-items-center justify-content-center small p-1"
              style={{
                height: '1.5rem',
                width: '1.5rem',
              }}
            >
              {filter.length}
            </Badge>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ maxHeight: '37rem', overflowY: 'auto' }}>
          {searcheable && (
            <div className="px-3 py-2">
              <input
                type="text"
                className="w-100 rounded p-1"
                placeholder="Search"
                value={searchString}
                onChange={(e) => {
                  setSearchString(e.target.value);
                }}
              />
            </div>
          )}
          {filterOptionsFiltered.map((filterOption) => {
            if ('label_name' in filterOption) {
              return (
                <div className="mx-2 border-bottom" key={uuid.v4()}>
                  {filterOption.label_name}
                </div>
              );
            }
            return (
              <Dropdown.Item
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedFilter(filterOption.key);
                }}
                className="d-flex align-items-center w-100 overflow-auto"
                key={uuid.v4()}
              >
                <CheckMark className="my-2" checked={filter.includes(filterOption.key)} readOnly>
                  {filterOption.badge !== undefined && (
                    <div className="me-2">{filterOption.badge}</div>
                  )}
                  {filterOption.display_name}
                </CheckMark>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

/**
 * Mirrors the ets_util.py file in the core api.
 */
import { CreditSchemeType } from '@nai/ets-polygons';
import _ from 'lodash';
import { getPolygonStatus } from './PolygonStatus';
import { CommonGeoJsonProperties, SegmentFeature } from './Segments';
import { getCreditScheme } from './segments_utils';

export const ets_schemes: CreditSchemeType[] = ['ets', 'ets_stock_change', 'ets_averaging'];

export function isEtsScheme(scheme: CreditSchemeType | undefined): boolean {
  return !!scheme && ets_schemes.includes(scheme);
}

export function isStandardForest(scheme: CreditSchemeType): boolean {
  return scheme === 'ets_stock_change' || scheme === 'ets_averaging';
}

export function isPermanentForest(scheme: CreditSchemeType): boolean {
  return scheme === 'ets';
}

/**
 * Check if the polygon definitely is an ets polygon.
 *
 * A polygon is definitely an ETS polygon if it is in one of the
 * ets schemes and has a CAA number.
 */
export function isEtsPolygon(seg_properties: CommonGeoJsonProperties): boolean {
  const scheme = getCreditScheme(seg_properties);
  if (isEtsScheme(scheme)) {
    if (seg_properties.ets_caa_number !== undefined && seg_properties.ets_caa_number !== null) {
      if (seg_properties.report_category !== 'native_carboncrop') {
        return true;
      }
    }
  }
  return false;
}

/**
 * Check if the polygon definitely is an registered or rejected polygon.
 *
 * This validation will not check schemes or CAA number.
 */
export function isNotRegisteredOrRejected(seg_properties: CommonGeoJsonProperties): boolean {
  const status = getPolygonStatus(seg_properties.status);
  if (status !== 'registered' && status !== 'rejected') {
    return true;
  }
  return false;
}

/**
 * Check if segmentation meet minium criteria to be ETS CAA.
 */
export function isEtsCandidate(seg_properties: CommonGeoJsonProperties): boolean {
  // Notice: Once CAA number is set, temporarily treat as CAA candidate
  // But further validation is required
  if (isEtsPolygon(seg_properties)) {
    return true;
  }

  const scheme = getCreditScheme(seg_properties);
  if (isEtsScheme(scheme)) {
    // Eligible report category only
    const category = seg_properties.report_category;

    if (
      category === 'existing_indigenous_ets_forest' ||
      category === 'existing_exotic_ets_forest'
    ) {
      return true;
    }
  }
  return false;
}

/**
 * Sort CAA's by their CAA number
 */
export function sortCaas(features: SegmentFeature[]): SegmentFeature[] {
  const usedCaas = features.filter((feature) => {
    return (
      isEtsCandidate(feature.properties) &&
      !feature.properties.is_excluded &&
      !feature.properties.is_ets_registered
    );
  });

  const sortedCaas = _.orderBy(usedCaas, (feature): number => {
    return feature.properties?.ets_caa_number ?? -1;
  });
  return sortedCaas;
}

/**
 * Group CAAs by their groupkey
 */
export function groupCaas(features: SegmentFeature[]): SegmentFeature[][] {
  const sortedCaas = sortCaas(features);

  // Cluster CAA's if they are in the same group AND their group key is not
  // undefined. This is not easily implemented in a functional manner. It could
  // probably be implemented as a reducer, but I think that would lose
  // clarity
  const groupedCaas: SegmentFeature[][] = [];
  sortedCaas.forEach((caa) => {
    // A CAA without a group is it's own group
    if (!caa.properties.mpi_report?.group_key) {
      groupedCaas.push([caa]);
      return;
    }

    // Check if there are any existing groups:
    const existingGroupIndex = groupedCaas.findIndex((group) => {
      return group[0].properties.mpi_report?.group_key === caa.properties.mpi_report?.group_key;
    });

    if (existingGroupIndex === -1) {
      // Did not fit into existing group
      groupedCaas.push([caa]);
    } else {
      groupedCaas[existingGroupIndex].push(caa);
    }
  });
  return groupedCaas;
}

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckSquare,
  faClipboard,
  faDownload,
  faLocationArrow,
  faPuzzlePiece,
  faStepBackward,
  faStepForward,
  faSync,
  faTimes,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import mixpanel from 'mixpanel-browser';
import React, { Suspense, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  createSearchParams,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { emptyContextState } from './contexts/customer-context-operations/ContextState';
import { CustomerContextProvider, customerContextReducer } from './contexts/CustomerContext';
import { StoresProvider } from './contexts/remote-data/sources/StoreContext';
import { Loading } from './Loading';

import './aws-amplify-config';
import './components/finance/register-chart-js';
import { ErrorTester } from './customer/ErrorTester';
import { NotFound } from './error-pages/NotFound';
import './theme/theme_combined.scss';
import SitesDashboardV2 from './company_dashboard/SitesDashboardV2';

global.Buffer = global.Buffer || require('buffer').Buffer; // eslint-disable-line

const HomePage = React.lazy(() => import('./submit/HomePage'));
const CustomerMapPage = React.lazy(() => import('./customer/MapPage'));
const AllCustomerMapPage = React.lazy(() => import('./customers-map/AllCustomerMapPage'));
const MpiEvidenceReportPage = React.lazy(() => import('./report/MpiEvidenceReportPage'));
const ProtectedRoute = React.lazy(() => import('./components/auth/ProtectedRoute'));
const PhotosPage = React.lazy(() => import('./photos/PhotosPage'));
const QgisTokenPage = React.lazy(() => import('./admin/ApiTokenPage'));
const LandAssessmentReportPage = React.lazy(() => import('./report/LandAssessmentPage'));
const RegisteredAreaReportPage = React.lazy(() => import('./report/RegisteredAreaReportPage'));
const ReportsPage = React.lazy(() => import('./report/ReportsPage'));
const PortalPage = React.lazy(() => import('./portal/PortalPage'));
const CCUTraceMapPage = React.lazy(() => import('./ccu-trace/Page'));
const CustomerTitlesPage = React.lazy(() => import('./customer-titles/CustomerTitlesPage'));
const Mer2023Page = React.lazy(() => import('./mer/Mer2018-2022Page'));
const Per2023Page = React.lazy(() => import('./per/Per2023Page'));
const LoginScreen = React.lazy(() => import('./components/auth/LoginScreen'));
const ServiceFeeInvoicePage = React.lazy(
  () => import('./report/service_fee_invoice/ServiceFeeInvoicePage'),
);
const CompanyManagerPage = React.lazy(() => import('./companies/CompanyManagerPage'));
const CustomerPageContainer = React.lazy(() => import('./customer/CustomerPageContainer'));
const CompanyPageContainer = React.lazy(() => import('./company_dashboard/CompanyPageContainer'));
const CreateSitePage = React.lazy(() => import('./customer-titles/CreateSitePage'));
const CompanySettingsPage = React.lazy(() => import('./company_dashboard/CompanySettingsPage'));
const CompanyTasksDashboard = React.lazy(() => import('./company_dashboard/CompanyTasksDashboard'));
const CompanySitesMap = React.lazy(() => import('./company_dashboard/CompanySitesMap'));
const SiteTasksDashboard = React.lazy(() => import('./company_dashboard/SiteTasksDashboard'));
const TaskRouterPage = React.lazy(() => import('./customer/TaskRouterPage'));
const InvoicesListPage = React.lazy(() => import('./invoice/InvoicesListPage'));

const AllScripts =
  process.env.NODE_ENV === 'development' && React.lazy(() => import('./live-scripts/AllScripts'));

const ScribblePage = React.lazy(() => import('./scribble/Page'));
library.add(
  faTimes,
  faPuzzlePiece,
  faLocationArrow,
  faCheckSquare,
  faClipboard,
  faStepForward,
  faStepBackward,
  faUpload,
  faDownload,
  faSync,
);

ReactGA.initialize('G-D6L2YS8WHW');
mixpanel.init('ec611e91d5f485c6231eb7adb8e1cc1b', {
  debug: true,
  persistence: 'localStorage',
});

/**
 * Redirects were removed in react-router 6. For more information:
 * https://gist.github.com/mjackson/b5748add2795ce7448a366ae8f8ae3bb
 */
interface RedirectProps {
  to: string;
}

const RedirectElement = ({ to }: RedirectProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fullUrl = to.replace(/:\w+/g, (param) => {
      return params[param.slice(1)] ?? '';
    });
    navigate({
      pathname: fullUrl,
      search: `?${createSearchParams(searchParams)}`,
    });
  }, [to, navigate, params, searchParams]);
  return null;
};

const RedirectExternal = ({ to }: RedirectProps) => {
  useEffect(() => {
    window.location.replace(to);
  }, [to]);
  return null;
};

// eslint-disable-next-line react/prefer-stateless-function
const App: React.FC = () => {
  const [unitedstate, dispatcher] = React.useReducer(customerContextReducer, {
    ...emptyContextState,
  });

  const { pathname } = useLocation();

  useEffect(() => {
    // Log the page change to mixpanel
    mixpanel.track_pageview();
  }, [pathname]);

  return (
    <CustomerContextProvider state={unitedstate} dispatcher={dispatcher}>
      <StoresProvider>
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
            <title>CarbonCrop | App</title>
          </Helmet>
          <Suspense fallback={<Loading stage={2} />}>
            <Routes>
              {/*
 ██████ ██    ██ ███████ ████████  ██████  ███    ███ ███████ ██████
██      ██    ██ ██         ██    ██    ██ ████  ████ ██      ██   ██
██      ██    ██ ███████    ██    ██    ██ ██ ████ ██ █████   ██████
██      ██    ██      ██    ██    ██    ██ ██  ██  ██ ██      ██   ██
 ██████  ██████  ███████    ██     ██████  ██      ██ ███████ ██   ██
 */}
              <Route path="/customer/:customerId" element={<CustomerPageContainer />}>
                <Route path="" element={<RedirectElement to="/customer/:customerId/map" />} />
                <Route path="map" element={<CustomerMapPage />} />
                <Route path="overview" element={<CustomerMapPage />} />
                <Route path="titles" element={<CustomerTitlesPage />} />
                <Route path="tasks">
                  <Route path="" element={<SiteTasksDashboard />} />
                  <Route path=":taskId" element={<TaskRouterPage />} />
                </Route>
                <Route path="reports">
                  <Route path="" element={<ReportsPage />} />
                  <Route path="registered_areas" element={<RegisteredAreaReportPage />} />
                  <Route
                    path="land_assessment"
                    element={<LandAssessmentReportPage show_full_report />}
                  />
                  <Route
                    path="land_assessment_lite"
                    element={<LandAssessmentReportPage show_full_report={false} />}
                  />

                  <Route path="mpi_evidence" element={<MpiEvidenceReportPage />} />
                </Route>

                <Route path="invoices">
                  <Route path=":invoiceId" element={<ServiceFeeInvoicePage />} />
                  <Route path="" element={<InvoicesListPage />} />
                </Route>
                <Route path="fieldscan" element={<PhotosPage />} />

                <Route path="mer/2023" element={<Mer2023Page />} />
                <Route path="per/2023" element={<Per2023Page />} />
              </Route>
              {/*
██████   █████  ███████ ██   ██ ██████   ██████   █████  ██████  ██████
██   ██ ██   ██ ██      ██   ██ ██   ██ ██    ██ ██   ██ ██   ██ ██   ██
██   ██ ███████ ███████ ███████ ██████  ██    ██ ███████ ██████  ██   ██
██   ██ ██   ██      ██ ██   ██ ██   ██ ██    ██ ██   ██ ██   ██ ██   ██
██████  ██   ██ ███████ ██   ██ ██████   ██████  ██   ██ ██   ██ ██████
 */}
              <Route
                path="/portal"
                element={
                  <ProtectedRoute>
                    <PortalPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/companies"
                element={
                  <ProtectedRoute>
                    <CompanyManagerPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/companies/:companyId" element={<CompanyPageContainer />}>
                <Route path="" element={<RedirectElement to="/companies/:companyId/sites" />} />
                <Route path="sites" element={<SitesDashboardV2 />} />
                <Route path="customer/create" element={<CreateSitePage />} />
                <Route path="settings" element={<CompanySettingsPage />} />
                <Route path="tasks" element={<CompanyTasksDashboard />} />
                <Route path="map" element={<CompanySitesMap />} />
              </Route>
              <Route path="/login" element={<LoginScreen />} />
              {/*
 ██████  ████████ ██   ██ ███████ ██████
██    ██    ██    ██   ██ ██      ██   ██
██    ██    ██    ███████ █████   ██████
██    ██    ██    ██   ██ ██      ██   ██
 ██████     ██    ██   ██ ███████ ██   ██
 */}
              <Route path="/carbontracer" element={<CCUTraceMapPage />} />
              <Route
                path="/ccp:customerId"
                element={<RedirectElement to="/customer/:customerId" />}
              />
              <Route
                path="/ccp:customerId/titles"
                element={<RedirectElement to="/customer/:customerId/titles" />}
              />
              <Route
                path="/customers/map"
                element={
                  <ProtectedRoute>
                    <AllCustomerMapPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users/me/token"
                element={
                  <ProtectedRoute>
                    <QgisTokenPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/error-tester"
                element={
                  <ProtectedRoute>
                    <ErrorTester />
                  </ProtectedRoute>
                }
              />
              <Route path="/submit" element={<HomePage />} />
              <Route
                path="/scribble"
                element={
                  <ProtectedRoute>
                    <ScribblePage />
                  </ProtectedRoute>
                }
              />
              {/* Hack to make the tamata hauha submit link work in prod */}
              <Route
                path="/tamata-hauha/submit"
                element={
                  <RedirectExternal to="https://app.carboncrop.com/submit?company_key=3a4ef7c7-fd2e-46fb-82f7-cb85c2c3da59" />
                }
              />
              {AllScripts && (
                <Route
                  path="scripts"
                  element={
                    <ProtectedRoute>
                      <AllScripts />
                    </ProtectedRoute>
                  }
                />
              )}
              <Route
                path="/"
                element={<RedirectExternal to="https://www.carboncrop.com/for-landholders" />}
              />
              <Route path="*" element={<NotFound />} />
              {/*
██████  ███████ ██████  ██████  ███████  ██████  █████  ████████ ███████ ██████
██   ██ ██      ██   ██ ██   ██ ██      ██      ██   ██    ██    ██      ██   ██
██   ██ █████   ██████  ██████  █████   ██      ███████    ██    █████   ██   ██
██   ██ ██      ██      ██   ██ ██      ██      ██   ██    ██    ██      ██   ██
██████  ███████ ██      ██   ██ ███████  ██████ ██   ██    ██    ███████ ██████
*/}
              <Route path="/admin/qgis-token" element={<RedirectElement to="/users/me/token" />} />
              <Route path="/project">
                <Route path=":customerId">
                  <Route path="" element={<RedirectElement to="/customer/:customerId" />} />
                  <Route
                    path="titles"
                    element={<RedirectElement to="/customer/:customerId/titles" />}
                  />
                </Route>
              </Route>
              <Route path="/projects/map" element={<RedirectElement to="/customers/map" />} />
              <Route
                path="/report-v2/:customerId"
                element={<RedirectElement to="/customer/:customerId/reports/land_assessment" />}
              />
              <Route
                path="/report-v2-lite/:customerId"
                element={
                  <RedirectElement to="/customer/:customerId/reports/land_assessment_lite" />
                }
              />
              <Route
                path="/mpi/evidence-report/:customerId"
                element={<RedirectElement to="/customer/:customerId/reports/mpi_evidence" />}
              />
              <Route
                path="/photos/:customerId"
                element={<RedirectElement to="/customer/:customerId/fieldscan" />}
              />
              <Route
                path="/report/:customerId"
                element={<RedirectElement to="/report-v2/:customerId" />}
              />
              <Route path="/ccutrace" element={<RedirectElement to="/cctrace" />} />
              <Route path="/cctrace" element={<RedirectElement to="/carbontracer" />} />
            </Routes>
          </Suspense>
        </HelmetProvider>
      </StoresProvider>
    </CustomerContextProvider>
  );
};

export default App;

/* eslint-disable camelcase */
// eslint-disable-next-line import/no-unresolved
import {
  etsSpecies as tupuake_etsSpecies,
  predominant_species,
  SpeciesType,
  ETSSpecies,
} from '@nai/ets-polygons';
import { category_colors_v2 } from '../theme/colors';
import { ets_schemes } from './ets_util';
import { CommonGeoJsonProperties } from '../apis/generated';

export type BadgeType =
  | 'ets_eligible'
  | 'potentially_ets_eligible'
  | 'not_ets_eligible'
  | ''
  | 'ncc';

export type CreditSchemeType = NonNullable<CommonGeoJsonProperties['credit_scheme']>;

export type EtsSpeciesType = ETSSpecies;

// Note: 'other_vegetation' is deprecated.
// Exists for backwards compatibility with old customers and ets-polygons package.
export type ReportCategoryType =
  | CashflowCategoryType
  | 'scattered_forest'
  | 'pre_1990_forest'
  | 'other_vegetation'
  | 'pasture';

export type CashflowCategoryType =
  | 'existing_indigenous_ets_forest'
  | 'future_native_planting'
  | 'existing_exotic_ets_forest'
  | 'future_exotic_planting'
  | 'potential_ets_forest'
  | 'native_carboncrop';

interface SpeciesOptionData {
  val: SpeciesType;
  text: string;
}

export interface EtsSpeciesOptionData {
  val: EtsSpeciesType;
  text: string;
}

export interface CategoryOptionData {
  // Which ReportCategoryType this data is for
  val: ReportCategoryType;

  // Human readable title
  text: string;

  // Short text to display next to financial graph
  badge: BadgeType;

  // Color for icons/backgrounds etc with the new report scheme
  color_v2: string;

  striped: boolean;

  // What credit schemes can the user select for this report category?
  allowedCreditSchemes: CreditSchemeType[];
}

interface CreditSchemeOptionData {
  val: CreditSchemeType;
  text: string;
}

export const creditSchemes: Array<CreditSchemeOptionData> = [
  { val: 'ets', text: 'ETS (Permanent)' },
  { val: 'ets_averaging', text: 'ETS (Averaging)' },
  { val: 'ets_stock_change', text: 'ETS (Stock Change)' },
  { val: 'alternative_scheme', text: 'CCU' },
];

// Please adjust dropdown item order by updating ets-polygon data list
export const predominantSpecies: Array<SpeciesOptionData> = predominant_species.map((data) => {
  return { val: data.key, text: data.display_name };
});

export const etsSpecies: Array<EtsSpeciesOptionData> = tupuake_etsSpecies.map((data) => {
  return {
    val: data.key,
    text: data.display_name,
  };
});

export const reportCategories: Array<CategoryOptionData> = [
  {
    val: 'existing_indigenous_ets_forest',
    text: 'ETS Eligible Native Forest',
    badge: 'ets_eligible',
    color_v2: category_colors_v2.existing_indigenous_ets_forest,
    striped: false,
    allowedCreditSchemes: ets_schemes,
  },
  {
    val: 'existing_exotic_ets_forest',
    text: 'ETS Eligible Exotic Forest',
    badge: 'ets_eligible',
    color_v2: category_colors_v2.existing_exotic_ets_forest,
    striped: false,
    allowedCreditSchemes: ets_schemes,
  },
  {
    val: 'pre_1990_forest',
    text: 'Ineligible/Excluded',
    badge: 'not_ets_eligible',
    color_v2: category_colors_v2.pre_1990_forest,
    striped: false,
    allowedCreditSchemes: [],
  },
  {
    val: 'future_native_planting',
    text: 'Future Native Planting',
    badge: 'ets_eligible',
    color_v2: category_colors_v2.future_native_planting,
    striped: true,
    allowedCreditSchemes: ets_schemes,
  },
  {
    val: 'future_exotic_planting',
    text: 'Future Exotic Planting',
    badge: 'ets_eligible',
    color_v2: category_colors_v2.future_exotic_planting,
    striped: true,
    allowedCreditSchemes: ets_schemes,
  },
  {
    val: 'potential_ets_forest',
    text: 'Potential Regeneration',
    badge: 'potentially_ets_eligible',
    color_v2: category_colors_v2.potential_ets_forest,
    striped: true,
    allowedCreditSchemes: ets_schemes,
  },
  {
    val: 'scattered_forest',
    text: 'Scattered Forest',
    badge: 'not_ets_eligible',
    color_v2: category_colors_v2.scattered_forest,
    striped: false,
    allowedCreditSchemes: ets_schemes,
  },
  {
    val: 'native_carboncrop',
    text: 'CarbonCrop Units',
    badge: 'ncc',
    color_v2: category_colors_v2.native_carboncrop,
    striped: false,
    allowedCreditSchemes: ['alternative_scheme'],
  },
  {
    val: 'pasture',
    text: 'Pasture & Non-Forest',
    badge: '',
    color_v2: category_colors_v2.pasture_no_forest,
    striped: false,
    allowedCreditSchemes: [],
  },
];

type categoryMap = { [key in ReportCategoryType]: CategoryOptionData };
export const allCategoriesByName: categoryMap = reportCategories.reduce((obj, item) => {
  return {
    ...obj,
    [item.val]: item,
  };
}, {} as categoryMap);

export const getMergedCategoryName = (category: ReportCategoryType): string => {
  switch (category) {
    case 'existing_indigenous_ets_forest':
      return 'ETS Registered native forest';
    case 'existing_exotic_ets_forest':
      return 'ETS Registered exotic forest';
    default:
      return 'Unknown';
  }
};

import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Dimensions, SiteSummary } from '../apis/generated';
import { useCompaniesCustomersV2 } from '../contexts/remote-data/useCompaniesCustomersV2';
import { useCurrentCompanyId } from '../contexts/remote-data/useCurrentCompanyId';
import { isEtsScheme } from '../models/ets_util';
import { getDimensionKeys } from './CarbonIntelligencePanel';
import { FilterDropdown, FilterLabel, FilterOption } from './FilterDropdown';
import styles from './SitesDashboard.module.scss';
import { assigneeFilter, makeAssigneeList } from './TasksDashboard';
import { useUserMe } from '../contexts/remote-data/useUserMe';
import { useCompanyUsers } from './useCompanyUsers';

export type SORT_KEY =
  | 'FOREST'
  | 'SEQUESTRATION'
  | 'EDIT_TIME'
  | 'SITE_NAME'
  | 'SITE_AREA'
  | undefined;

export const searchSite = (site: SiteSummary, key: string): boolean => {
  const searchKey = key.toLowerCase().trim();
  if (site.properties.name) {
    return site.properties.name.toLowerCase().includes(searchKey);
  }
  return (
    site.customer_id.toString().includes(searchKey) || `ccp${site.customer_id}`.includes(searchKey)
  );
};

type ForestCategoryKey = 'native' | 'exotic' | 'native_ets' | 'exotic_ets';

export const SitesFilters: React.FC<{
  sites: SiteSummary[] | undefined;
  setFilteredSites: (sites: SiteSummary[]) => void;
  setFilteredKeys?: (keys: string[] | undefined) => void;
}> = ({ sites, setFilteredSites, setFilteredKeys }) => {
  const companyId = useCurrentCompanyId();
  const { customers } = useCompaniesCustomersV2(companyId);

  const [searchString, setSearchString] = useState<string>('');

  const { userMe } = useUserMe();
  const { users } = useCompanyUsers(companyId);
  const [filterAssignee, setFilterAssignee] = useState<Array<number | undefined>>([]);

  const assignees: (FilterOption<number | undefined> | FilterLabel)[] = useMemo(
    () => makeAssigneeList(users, userMe),
    [users, userMe],
  );

  const isForestCategoryInDimensions = (
    forestCategory: ForestCategoryKey,
    dimensions: Dimensions,
  ): boolean => {
    if (forestCategory === 'exotic') {
      return !isEtsScheme(dimensions.credit_scheme) && dimensions.forest_type === 'exotic';
    }
    if (forestCategory === 'native') {
      return !isEtsScheme(dimensions.credit_scheme) && dimensions.forest_type === 'native';
    }
    if (forestCategory === 'exotic_ets') {
      return isEtsScheme(dimensions.credit_scheme) && dimensions.forest_type === 'exotic';
    }
    if (forestCategory === 'native_ets') {
      return isEtsScheme(dimensions.credit_scheme) && dimensions.forest_type === 'native';
    }
    return false;
  };

  const filterKeys = useMemo(
    () =>
      <T,>(filterElements: T[], keyValidation: (dim: Dimensions, elemsFilter: T[]) => boolean) => {
        if (customers?.dimensions === undefined || filterElements.length === 0) {
          return undefined;
        }
        return getDimensionKeys(customers.dimensions, (d) => keyValidation(d, filterElements));
      },
    [customers?.dimensions],
  );

  const [filterRegistrationStatus, setFilterRegistrationStatus] = useState<
    Dimensions['registration_status'][]
  >([]);

  const [filterForestCategory, setFilterForestCategory] = useState<ForestCategoryKey[]>([]);

  const [filterPlantingStatus, setFilterPlantingStatus] = useState<Dimensions['planting_status'][]>(
    [],
  );

  const filteredKeys = useMemo(() => {
    let returnedKeys: string[] | undefined;
    const addFilteredKeys = (keys: string[] | undefined) => {
      if (keys !== undefined) {
        if (returnedKeys === undefined) {
          returnedKeys = keys;
        } else {
          returnedKeys = returnedKeys.filter((val) => keys.includes(val));
        }
      }
    };
    addFilteredKeys(
      filterKeys<Dimensions['registration_status']>(filterRegistrationStatus, (dim, elemsFilter) =>
        elemsFilter.includes(dim.registration_status),
      ),
    );
    addFilteredKeys(
      filterKeys<Dimensions['planting_status']>(filterPlantingStatus, (dim, elemsFilter) =>
        elemsFilter.includes(dim.planting_status),
      ),
    );
    addFilteredKeys(
      filterKeys<ForestCategoryKey>(filterForestCategory, (dim, elemsFilter) =>
        elemsFilter.some((cat) => isForestCategoryInDimensions(cat, dim)),
      ),
    );

    return returnedKeys;
  }, [filterKeys, filterPlantingStatus, filterForestCategory, filterRegistrationStatus]);

  useEffect(() => {
    if (setFilteredKeys) {
      setFilteredKeys(filteredKeys);
    }
  }, [filteredKeys, setFilteredKeys]);

  const plantingStatusFilterOptions: FilterOption<Dimensions['planting_status']>[] = [
    {
      display_name: 'Draft',
      key: 'draft',
    },
    {
      display_name: 'Confirmed',
      key: 'confirmed',
    },
    {
      display_name: 'Planted',
      key: 'planted',
    },
    {
      display_name: 'No Status',
      key: null as any, // eslint-disable-line @typescript-eslint/no-explicit-any
    },
  ];

  const registrationStatusFilterOptions: FilterOption<Dimensions['registration_status']>[] = [
    {
      display_name: 'Draft',
      key: 'draft',
    },
    {
      display_name: 'Rejected',
      key: 'rejected',
    },
    {
      display_name: 'Registered',
      key: 'registered',
    },
    {
      display_name: 'Submitted',
      key: 'submitted',
    },
  ];

  const forestCategoryFilterOptions: (FilterOption<ForestCategoryKey> | FilterLabel)[] = [
    { label_name: 'Native' },
    {
      display_name: 'Native',
      key: 'native',
    },
    {
      display_name: 'Native ETS',
      key: 'native_ets',
    },
    { label_name: 'Exotic' },

    {
      display_name: 'Exotic',
      key: 'exotic',
    },
    {
      display_name: 'Exotic ETS',
      key: 'exotic_ets',
    },
  ];

  const filterByMeasure = (site: SiteSummary, measures?: string[]) => {
    if (measures === undefined) {
      return true;
    }
    return measures.some((key) => site.measures[key] !== undefined);
  };

  const filteredSortedSites = useMemo(() => {
    return sites
      ?.filter((site) => searchSite(site, searchString))
      .filter((site) => filterByMeasure(site, filteredKeys))
      .filter((site) => assigneeFilter(site.properties.assignee, filterAssignee));
  }, [sites, searchString, filteredKeys, filterAssignee]);

  useEffect(() => {
    setFilteredSites(filteredSortedSites ?? []);
  }, [filteredSortedSites, setFilteredSites]);

  return (
    <div>
      <div className="d-flex flex-row  pt-2 pb-4 gap-2 flex-wrap">
        <Form.Control
          type="text"
          placeholder="Search"
          className={`${styles.search_input} `}
          style={{ width: '15em' }}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <FilterDropdown
          filterName="Assignee"
          filterOptions={assignees}
          filter={filterAssignee}
          setFilter={setFilterAssignee}
          searcheable
        />
        <FilterDropdown
          filterName="Forest Category"
          filterOptions={forestCategoryFilterOptions}
          filter={filterForestCategory}
          setFilter={setFilterForestCategory}
        />
        <FilterDropdown
          filterName="Planting Status"
          filterOptions={plantingStatusFilterOptions}
          filter={filterPlantingStatus}
          setFilter={setFilterPlantingStatus}
        />
        <FilterDropdown
          filterName="Registration Status"
          filterOptions={registrationStatusFilterOptions}
          filter={filterRegistrationStatus}
          setFilter={setFilterRegistrationStatus}
        />
      </div>
    </div>
  );
};

import {
  faDownload,
  faPlus,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import uuid from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';
import { unparse } from 'papaparse';
import { Dimensions, SiteSummary } from '../apis/generated';
import { IconButton } from '../components/buttons/IconButton';
import { UrlSelector } from '../components/UrlSelector';
import { useCompaniesCustomersV2 } from '../contexts/remote-data/useCompaniesCustomersV2';
import { useCompany } from '../contexts/remote-data/useCompany';
import { useCurrentCompanyId } from '../contexts/remote-data/useCurrentCompanyId';
import CarbonIntelligencePanel from './CarbonIntelligencePanel';
import CustomerEntryV2, { calculateForestArea, calculateSequestration } from './CustomerEntryV2';
import styles from './SitesDashboard.module.scss';
import { useCompanyUsers } from './useCompanyUsers';
import stylesTask from './TaskEntry.module.scss';
import CO2NZDToggle from '../customer/financials/CO2NZUToggle';
import { SitesFilters } from './SitesFilters';

const DISPLAY_SIZE = 10;
export type SORT_KEY =
  | 'FOREST'
  | 'SEQUESTRATION'
  | 'EDIT_TIME'
  | 'SITE_NAME'
  | 'SITE_AREA'
  | undefined;

export const searchSite = (site: SiteSummary, key: string): boolean => {
  const searchKey = key.toLowerCase().trim();
  if (site.properties.name) {
    return site.properties.name.toLowerCase().includes(searchKey);
  }
  return (
    site.customer_id.toString().includes(searchKey) || `ccp${site.customer_id}`.includes(searchKey)
  );
};

const m2_to_ha = (m2: number) => m2 / 1e4;

const SitesDashboardV2: React.FC = () => {
  const navigate = useNavigate();
  const companyId = useCurrentCompanyId();
  const { customers, setCustomers, state, error } = useCompaniesCustomersV2(companyId);

  const [numDisplay, setNumDisplay] = useState<number>(DISPLAY_SIZE);

  const [ascOrder, setAscOrder] = useState<boolean | undefined>(true);
  const [sortKey, setSortKey] = useState<SORT_KEY>(undefined);
  const sortSites = (key: SORT_KEY) => {
    setSortKey(key);
    setAscOrder(!ascOrder);
  };

  const { users } = useCompanyUsers(companyId);

  const [filteredKeys, setFilteredKeys] = useState<string[] | undefined>(undefined);

  const sortedSites = useMemo(() => {
    if (sortKey === 'SITE_NAME') {
      return _.orderBy(
        customers?.sites || [],
        [(site) => site.properties.name || `CCP${site.customer_id}`],
        [!ascOrder ? 'desc' : 'asc'],
      );
    }
    if (sortKey === 'FOREST') {
      return _.orderBy(
        customers?.sites || [],
        [(site) => calculateForestArea(site, filteredKeys)],
        [!ascOrder ? 'desc' : 'asc'],
      );
    }
    if (sortKey === 'SEQUESTRATION') {
      return _.orderBy(
        customers?.sites || [],
        [(site) => calculateSequestration(site, 0, filteredKeys)],
        [!ascOrder ? 'desc' : 'asc'],
      );
    }
    if (sortKey === 'EDIT_TIME') {
      return _.orderBy(
        customers?.sites || [],
        [(site) => site.edit_time ?? '1/1/1970'],
        [!ascOrder ? 'desc' : 'asc'],
      );
    }
    if (sortKey === 'SITE_AREA') {
      return _.orderBy(
        customers?.sites || [],
        [(site) => site.site_area_m2 ?? 0],
        [!ascOrder ? 'desc' : 'asc'],
      );
    }

    return _.orderBy(customers?.sites || [], [(site) => site.create_time], ['desc']);
  }, [ascOrder, customers, sortKey, filteredKeys]);

  const [filteredSortedSites, setFilteredSortedSites] = useState<SiteSummary[]>([]);

  const visibleSites = useMemo(() => {
    return filteredSortedSites.slice(0, numDisplay);
  }, [filteredSortedSites, numDisplay]);

  const fetchSites = useCallback(async () => {
    setNumDisplay((n) => Math.min(n + DISPLAY_SIZE, filteredSortedSites.length));
  }, [filteredSortedSites.length]);

  const [showShareLinkDlg, setShowShareLinkDlg] = useState(false);

  const company = useCompany(companyId);

  const submitUrl = company?.company?.key
    ? `${window.location.origin}/submit?company_key=${company?.company?.key}`
    : undefined;

  const hideShareLinkDlg = () => setShowShareLinkDlg(false);

  const getIcon = (key: SORT_KEY, order: boolean | undefined) => {
    if (sortKey !== key) {
      return faSort;
    }
    return order ? faSortDown : faSortUp;
  };

  const downloadCSV = useCallback(() => {
    if (!customers || !filteredSortedSites) {
      return;
    }
    const csvString = unparse(
      filteredSortedSites.map((site) => {
        return {
          'Site name': site.properties.name || `CCP${site.customer_id}`,
          'Site ID': site.customer_id.toString(),
          'Area (ha)': site.site_area_m2 ? _.round(m2_to_ha(site.site_area_m2), 1) : '',
          Assignee: users?.find((user) => user.user_id === site.properties.assignee)?.email || '',
          ..._.fromPairs(
            _.range(10).map((i) => [
              `Sequestration ${i + customers.start_year}`,
              calculateSequestration(site, i, filteredKeys),
            ]),
          ),
        };
      }),
    );

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvString}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `Sites_${new Date().toISOString()}.csv`);
    document.body.appendChild(link);
    link.click();
  }, [filteredSortedSites, filteredKeys, users, customers]);

  const [showtCO2, setShowtCO2] = useState(true);

  return (
    <div className="bg-birch min-vh-100 flex-grow-1">
      <Container fluid className="m-0 p-4">
        <Modal show={showShareLinkDlg} onHide={hideShareLinkDlg} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Share link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              This link can be shared with users to create their own sites. These sites will appear
              in your list.
            </p>
            <UrlSelector url={submitUrl || 'Loading, please wait...'} />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="dark" onClick={hideShareLinkDlg}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div className=" d-flex mb-4 justify-content-between align-items-baseline">
          <div className="d-flex flex-row gap-2 align-items-baseline">
            <h3>Sites</h3>
            {state === 'downloading' && (
              <Spinner
                animation="border"
                className="me-2"
                style={{ height: '1.3em', width: '1.3em' }}
              />
            )}
            {state === 'error' && (
              <Alert variant="danger">Failed to load properties: {error?.toString()}</Alert>
            )}
          </div>
          <div className="d-flex flex-row gap-2">
            <Button
              variant="primary"
              onClick={() => navigate(`/companies/${companyId}/customer/create`)}
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              Add Site
            </Button>
            <Button variant="primary" onClick={() => setShowShareLinkDlg(true)}>
              Share link
            </Button>

            <IconButton
              className="d-flex gap-2"
              icon={faDownload}
              onClick={downloadCSV}
              disabled={sortedSites.length === 0 || !users}
            >
              Export CSV
            </IconButton>
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap justify-content-between">
          <SitesFilters
            sites={sortedSites}
            setFilteredSites={setFilteredSortedSites}
            setFilteredKeys={setFilteredKeys}
          />
          <CO2NZDToggle showtCO2={showtCO2} setShowtCO2={setShowtCO2} />
        </div>

        <CarbonIntelligencePanel
          sites={filteredSortedSites}
          showtCO2={showtCO2}
          dimensions={
            customers &&
            (filteredKeys === undefined
              ? customers.dimensions
              : (_.pick(customers.dimensions, filteredKeys) as Record<string, Dimensions>))
          }
        />
        <div className={`p-2 ${styles.sites_table}`}>
          <Row className="text-muted m-0 p-3">
            <Col lg={3} className="p-0 gap-2 d-flex">
              <strong>Site</strong>
              <FontAwesomeIcon
                icon={getIcon('SITE_NAME', ascOrder)}
                className="ms-2 cursor-pointer"
                onClick={() => {
                  sortSites('SITE_NAME');
                }}
              />
            </Col>
            <Col lg={2}>
              <strong>Area</strong>
              <FontAwesomeIcon
                icon={getIcon('SITE_AREA', ascOrder)}
                className="ms-2 cursor-pointer"
                onClick={() => {
                  sortSites('SITE_AREA');
                }}
              />
            </Col>
            <Col lg={2}>
              <strong>Forest</strong>
              <FontAwesomeIcon
                icon={getIcon('FOREST', ascOrder)}
                className="ms-2 cursor-pointer"
                onClick={() => {
                  sortSites('FOREST');
                }}
              />
            </Col>
            <Col lg={2}>
              <strong>Sequestration {customers?.start_year}</strong>
              <FontAwesomeIcon
                icon={getIcon('SEQUESTRATION', ascOrder)}
                className="ms-2 cursor-pointer"
                onClick={() => {
                  sortSites('SEQUESTRATION');
                }}
              />
            </Col>
            <Col lg={2}>
              <strong>Last edited</strong>
              <FontAwesomeIcon
                icon={getIcon('EDIT_TIME', ascOrder)}
                className="ms-2 cursor-pointer"
                onClick={() => {
                  sortSites('EDIT_TIME');
                }}
              />
            </Col>
            <Col lg={1}>
              <strong>Assignee</strong>
            </Col>
          </Row>
          {customers ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={fetchSites}
              hasMore={visibleSites.length < customers?.sites.length}
            >
              {visibleSites &&
                visibleSites.map((customer) => {
                  return (
                    <CustomerEntryV2
                      customer={customer}
                      key={customer.customer_id}
                      filterDimensionsKeys={filteredKeys}
                      setCustomer={(site: SiteSummary) => {
                        setCustomers((siteList) => ({
                          ...siteList,
                          sites: siteList.sites.map((site_) => {
                            if (site_.customer_id !== site.customer_id) {
                              return site_;
                            }
                            return site;
                          }),
                        }));
                      }}
                    />
                  );
                })}
            </InfiniteScroll>
          ) : (
            <>
              {_.times(10, () => (
                <Row
                  key={uuid.v4()}
                  style={{ height: '5em' }}
                  className={`${stylesTask.task_row}`}
                />
              ))}
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default SitesDashboardV2;
